import { throttle } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import { isPreview } from '../../store/basic-params/basic-params-selectors';
import Wix from '../../services/wix-sdk-polyfill';

class ScrollListener extends Component {
  componentDidMount() {
    if (this.props.isPreview) {
      Wix.addEventListener(Wix.Events.SCROLL, this.handleScroll);
      return;
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll, false);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPreview === this.props.isPreview) {
      return;
    }

    if (this.props.isPreview) {
      Wix.addEventListener(Wix.Events.SCROLL, this.handleScroll);
    } else {
      Wix.removeEventListener(Wix.Events.SCROLL, this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.isPreview) {
      Wix.removeEventListener(Wix.Events.SCROLL, this.handleScroll);
      return;
    }

    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll, false);
    }
  }

  handleScroll = throttle((scroll) => this.props.onScroll(scroll), 500, { leading: true });

  render() {
    return null;
  }
}

ScrollListener.propTypes = {
  onScroll: PropTypes.func.isRequired,
  isPreview: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  isPreview: isPreview(state),
});

export default connect(mapRuntimeToProps)(ScrollListener);
