import React from 'react';
import { I18nextProvider } from 'react-i18next';
import 'reset-css';
import { initI18n, SECTION_POST_LIST } from '@wix/communities-blog-client-common';
import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { getLanguage, getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import AppLoaded from '../../../common/components/app-loaded';
import PostList from '../post-list';
import '../../../rich-content/components/rich-content-editor/rich-content-pg.global.scss';
import styles from './app-root.scss';
import { isInWix } from '../../../common/services/is-in-wix';
import { getLayoutMargins } from '../../../common/selectors/app-settings-selectors';
import { getLayoutType } from '../../../common/selectors/layout-selectors';

class AppRoot extends React.Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
  }

  getI18nConfig = (language, translations) => {
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { language, translations, padding } = this.props;
    const i18n = this.getI18nConfig(language, translations);

    return (
      <ResponsiveListener>
        <I18nextProvider i18n={i18n}>
          <div style={{ padding }} className={styles.baseStyles}>
            <PostList />
            <AppLoaded />
          </div>
        </I18nextProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  padding: getIsMobile(state)
    ? 0
    : getLayoutMargins({ state, section: SECTION_POST_LIST, layoutType: getLayoutType(state, SECTION_POST_LIST) }),
});

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
