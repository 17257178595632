import { omit, trimStart } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { SECTION_BLOG_POST_PAGE } from '@wix/communities-blog-client-common';
import { connect } from '../runtime-context';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import Wix from '../../services/wix-sdk-polyfill';

const OMIT_PROPS = ['dispatch', 'titleFontClassName', 'tReady', 'lng', 'contentFontClassNameWithStyle'];

const handleClick = (postLink, isSite) => (event) => {
  if (!isSite) {
    event.preventDefault();
    Wix.Utils.navigateToSection(
      {
        appDefinitionId: BLOG_APP_ID,
        sectionId: SECTION_BLOG_POST_PAGE,
        shouldRefreshIframe: false,
      },
      trimStart(postLink, '/'),
    );
  }
};

const PostLink = ({ postLink, addHoverClasses, children, className, isSite, ...props }) => {
  const linkClassName = classNames(addHoverClasses && 'blog-link-hover-color', className);
  const linkProps = omit(props, OMIT_PROPS);

  return (
    <a href={postLink} onClick={handleClick(postLink, isSite)} className={linkClassName} {...linkProps}>
      {children}
    </a>
  );
};

PostLink.propTypes = {
  postLink: PropTypes.string,
  addHoverClasses: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  isSite: PropTypes.bool,
};

PostLink.defaultProps = {
  addHoverClasses: true,
};

const mapRuntimeToProps = (state) => ({
  isSite: isSite(state),
});

export default connect(mapRuntimeToProps)(PostLink);
